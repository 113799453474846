import {takeEvery} from 'redux-saga/effects'
import {
  GET_GOODS_RECEIPT,
  GET_GOODS_RECEIPTS,
} from 'setup/redux/Actions/GoodsReceipt/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_GOODS_RECEIPTS, fetchData)
  yield takeEvery(GET_GOODS_RECEIPT, fetchData)
}

export default dataSaga
