export const GET_NEWS_LIST = 'GET_NEWS_LIST'
export const DELETE_NEWS_BY_ID = 'DELETE_NEWS_BY_ID'
export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID'
export const DELETE_NEWS_IN_BULK = 'DELETE_NEWS_IN_BULK'
export const ADD_NEWS = 'ADD_NEWS'
export const UPDATE_NEWS = 'UPDATE_NEWS'
export const SEND_NEWS_SMS = 'SEND_NEWS_SMS'
export const SEND_NEWS_NOTIFICATION = 'SEND_NEWS_NOTIFICATION'
export const NEWS_REPORT = 'NEWS_REPORT'
export const NEWS_APPROVAL_STATUS = 'NEWS_APPROVAL_STATUS'
export const NEWS_APPROVAL_INFO = 'NEWS_APPROVAL_INFO'
