export const GET_TEACHER_LIST = 'GET_TEACHER_LIST'
export const IN_ACTIVATE_USER = 'IN_ACTIVATE_USER'
export const GET_TEACHER_BY_ID = 'GET_TEACHER_BY_ID'
export const GET_TEACHER_CLASSES_BY_ID = 'GET_TEACHER_CLASSES_BY_ID'
export const GET_TEACHER_SUBJECTS_BY_ID = 'GET_TEACHER_SUBJECTS_BY_ID'
export const GET_TEACHER_QUALIFICATIONS_BY_ID = 'GET_TEACHER_QUALIFICATIONS_BY_ID'
export const GET_TEACHER_EXPERIENCE_BY_ID = 'GET_TEACHER_EXPERIENCE_BY_ID'
export const GET_TEACHER_CHILDREN_BY_ID = 'GET_TEACHER_CHILDREN_BY_ID'
export const DELETE_TEACHER_GENERAL_REMARKS = 'DELETE_TEACHER_GENERAL_REMARKS'
export const GET_TEACHER_GENERAL_REMARKS_BY_ID = 'GET_TEACHER_GENERAL_REMARKS_BY_ID'
export const ADD_TEACHER_GENERAL_REMARKS = 'ADD_TEACHER_GENERAL_REMARKS'
export const UPDATE_TEACHER_GENERAL_REMARKS_BY_ID = 'UPDATE_TEACHER_GENERAL_REMARKS_BY_ID'
export const GET_TEACHER_UPLOADED_FILES = 'GET_TEACHER_UPLOADED_FILES'
export const GET_TEACHER_FILE_TYPES = 'GET_TEACHER_FILE_TYPES'
export const ADD_TEACHER_UPLOAD_FILE = 'ADD_TEACHER_UPLOAD_FILE'
export const UPDATE_TEACHER = 'UPDATE_TEACHER'
export const ADD_TEACHER = 'ADD_TEACHER'
export const SMS_TEACHER_LOGIN_DETAILS = 'SMS_TEACHER_LOGIN_DETAILS'
export const GENERATE_TEACHER_LOGIN_REPORT = 'GENERATE_TEACHER_LOGIN_REPORT'
export const EMAIL_TEACHER_LOGIN_DETAILS = 'EMAIL_TEACHER_LOGIN_DETAILS'
export const GENERATE_TEACHER_LIST_REPORT_PDF = 'GENERATE_TEACHER_LIST_REPORT_PDF'
