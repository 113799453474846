export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function isLinkActive(currentUrl: string, link: string) {
  currentUrl = getCurrentUrl(currentUrl)
  if (!currentUrl || !link) {
    return false
  }

  if (currentUrl === link) {
    return true
  }

  if (currentUrl.indexOf(link) > -1) {
    return true
  }

  return false
}

/**
 * Check if the given item has the given url
 * in one of its sub links
 *
 * @param subLinks
 * @param activeItem
 */
export const hasActiveSubLink = (subLinks: any[] | undefined, currentUrl: string) => {
  if (!subLinks || !Array.isArray(subLinks)) {
    return false
  }

  currentUrl = getCurrentUrl(currentUrl)

  for (const subLink of subLinks) {
    if (subLink.subLinks) {
      if (hasActiveSubLink(subLink, currentUrl)) {
        return true
      }
    }

    // Check if the child has a link and is active
    if (
      subLink &&
      subLink.to &&
      currentUrl &&
      (subLink.to === currentUrl || currentUrl.includes(subLink.to))
    ) {
      return true
    }
  }

  return false
}
