import {
  REDUX_SAVE_LOGIN_INFO,
  REDUX_DESTROY_SESSION,
} from '../Actions/SignIn/ActionTypes/ReduxActionTypes'
import {
  REDUX_IMPERSONATE,
  REDUX_PROFILE_PICTURE_UPDATE,
  REDUX_REVERT_TO_ORIGINAL_USER,
  REDUX_SAVE_USER_SETTINGS,
  REDUX_SELECT_CHILD,
} from '../Actions/User/ActionTypes/ReduxActionTypes'
// import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  // MODULES,
  USER_TYPES,
} from 'common/Constants'
import {setLanguage} from 'common/i18n/Metronici18n'
import {REDUX_SAVE_SCHOOL_USER_PROFILE} from '../Actions/School/User/ActionTypes/ReduxActionTypes'

const initialState = {
  isSignout: false,
  isLoggedIn: undefined,
  accessToken: null,
  tenant: '',
  user: null,
  userType: '',
  selectedChild: null,
  appSettings: null,
  settings: null,
  permissions: [],
  impersonation: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_SAVE_LOGIN_INFO:
      const {
        accessToken = null,
        tenant = '',
        user = null,
        userType = '',
        applicationSettings = null,
        permissions,
      } = action.payload.result

      if ([USER_TYPES.STUDENT, USER_TYPES.PARENT].includes(userType)) {
        return state
      }

      if (applicationSettings) {
        // const {rename} = applicationSettings
        // MODULES.map((mod) => {
        //   rename[mod.value] && (mod.name = rename[mod.value])
        // })
      }
      return {
        ...state,
        accessToken,
        tenant,
        user,
        userType,
        appSettings: applicationSettings,
        isLoggedIn: accessToken ? true : false,
        isSignout: false,
        permissions,
      }
    case REDUX_SELECT_CHILD:
      return {
        ...state,
        selectedChild: action.payload.data,
      }
    case REDUX_SAVE_USER_SETTINGS:
      const {result} = action.payload
      let lang = result.applicationSettings.language
      if (!lang) {
        lang = 'en'
      }
      setLanguage(lang)
      return {
        ...state,
        settings: result,
      }
    case REDUX_DESTROY_SESSION:
      localStorage.clear()
      return {
        ...state,
        ...initialState,
        isSignout: true,
      }
    case REDUX_SAVE_SCHOOL_USER_PROFILE:
      return {...state, user: {...state.user, ...action.payload.result}}
    case REDUX_PROFILE_PICTURE_UPDATE:
      return {...state, user: {...state.user, ...action.payload}}
    case REDUX_IMPERSONATE:
      const impersonation = {
        accessToken: state.accessToken,
        user: state.user,
        userType: state.userType,
        selectedChild: state.selectedChild,
        appSettings: state.appSettings,
        settings: state.settings,
        permissions: state.permissions,
      }
      return {
        ...state,
        ...action.payload,
        impersonation,
      }
    case REDUX_REVERT_TO_ORIGINAL_USER:
      return {
        ...state,
        accessToken: state.impersonation.accessToken,
        user: state.impersonation.user,
        userType: state.impersonation.userType,
        selectedChild: state.impersonation.selectedChild,
        appSettings: state.impersonation.appSettings,
        settings: state.impersonation.settings,
        permissions: state.impersonation.permissions,
        impersonation: null,
      }
    default:
      return state
  }
}

export default reducer
