export const GET_SUBJECTS = 'GET_SUBJECTS'
export const GET_SUBJECT = 'GET_SUBJECT'
export const CREATE_SUBJECT = 'CREATE_SUBJECT'
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT'
export const GET_SUBJECTS_STUDENTS = 'GET_SUBJECTS_STUDENTS'
export const UPDATE_SUBJECT_SEQUENCE = 'UPDATE_SUBJECT_SEQUENCE'
export const GET_SUBJECT_SEQUENCE = 'GET_SUBJECT_SEQUENCE'
export const GENERATE_STUDENT_LIST = 'GENERATE_STUDENT_LIST'
export const GENERATE_OVERALL_SUBJECT_REPORT = 'GENERATE_OVERALL_SUBJECT_REPORT'
export const SET_SUBJECT_WEIGHTAGE = 'SET_SUBJECT_WEIGHTAGE'
export const STUDENT_SUBJECT_REPORT = 'STUDENT_SUBJECT_REPORT'
export const LOCK_SELECTED_OUTLINE = 'LOCK_SELECTED_OUTLINE'
export const UNLOCK_SELECTED_OUTLINE = 'UNLOCK_SELECTED_OUTLINE'
export const GET_PREVIOUS_SUBJECTS = 'GET_PREVIOUS_SUBJECTS'
export const GET_SUBJECT_EXAMS = 'GET_SUBJECT_EXAMS'
