import {applyMiddleware, createStore, compose} from 'redux'
import app from './Reducers/index'

import createSagaMiddleware from 'redux-saga'
import rootSaga from './Sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()

export default function configureStore() {
  // const store = createStore(app, applyMiddleware(sagaMiddleware))
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const persistedState = localStorage.getItem(process.env.REACT_APP_REDUX_STATE_KEY)
    ? JSON.parse(localStorage.getItem(process.env.REACT_APP_REDUX_STATE_KEY))
    : {}

  const store = createStore(
    app /* preloadedState, */,
    persistedState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )
  sagaMiddleware.run(rootSaga)

  store.subscribe(() => {
    localStorage.setItem(process.env.REACT_APP_REDUX_STATE_KEY, JSON.stringify(store.getState()))
  })

  return store
}
