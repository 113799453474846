import {takeEvery} from 'redux-saga/effects'
import {GET_INVOICE, GET_INVOICES} from 'setup/redux/Actions/Invoice/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_INVOICES, fetchData)
  yield takeEvery(GET_INVOICE, fetchData)
}

export default dataSaga
