export const EDIT_SCHOOL_USER_PROFILE = 'EDIT_SCHOOL_USER_PROFILE'

export const CHANGE_SCHOOL_USER_PASSWORD = 'CHANGE_SCHOOL_USER_PASSWORD'

export const GET_USER_QUALIFICATIONS = 'GET_USER_QUALIFICATIONS'
export const ADD_USER_QUALIFICATIONS = 'ADD_USER_QUALIFICATIONS'

export const GET_USER_EXPERIENCES = 'GET_USER_EXPERIENCES'
export const ADD_USER_EXPERIENCES = 'ADD_USER_EXPERIENCES'

export const GET_USER_CHILDREN = 'GET_USER_CHILDREN'
export const ADD_USER_CHILDREN = 'ADD_USER_CHILDREN'

export const GET_USER_BANK_INFO = 'GET_USER_BANK_INFO'
export const UPDATE_USER_BANK_INFO = 'UPDATE_USER_BANK_INFO'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_STAFF = 'GET_ALL_STAFF'
export const GET_ONE_STAFF = 'GET_ONE_STAFF'
export const GET_SMS_SERVICES = 'GET_SMS_SERVICES'

export const SEND_INACTIVE_USER_SMS = 'SEND_INACTIVE_USER_SMS'
