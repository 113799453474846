import {
  GET_SUBJECT,
  GET_SUBJECTS,
  GET_SUBJECTS_STUDENTS,
  UPDATE_SUBJECT,
  UPDATE_SUBJECT_SEQUENCE,
  GET_SUBJECT_SEQUENCE,
  GENERATE_STUDENT_LIST,
  GENERATE_OVERALL_SUBJECT_REPORT,
  SET_SUBJECT_WEIGHTAGE,
  CREATE_SUBJECT,
  STUDENT_SUBJECT_REPORT,
  LOCK_SELECTED_OUTLINE,
  UNLOCK_SELECTED_OUTLINE,
  GET_PREVIOUS_SUBJECTS,
  GET_SUBJECT_EXAMS,
} from 'setup/redux/Actions/Subject/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_SUBJECTS, fetchData)
  yield takeEvery(GET_SUBJECT, fetchData)
  yield takeEvery(GET_SUBJECTS_STUDENTS, fetchData)
  yield takeEvery(CREATE_SUBJECT, fetchData)
  yield takeEvery(UPDATE_SUBJECT, fetchData)
  yield takeEvery(UPDATE_SUBJECT_SEQUENCE, fetchData)
  yield takeEvery(GET_SUBJECT_SEQUENCE, fetchData)
  yield takeEvery(GENERATE_STUDENT_LIST, fetchData)
  yield takeEvery(GENERATE_OVERALL_SUBJECT_REPORT, fetchData)
  yield takeEvery(SET_SUBJECT_WEIGHTAGE, fetchData)
  yield takeEvery(STUDENT_SUBJECT_REPORT, fetchData)
  yield takeEvery(LOCK_SELECTED_OUTLINE, fetchData)
  yield takeEvery(UNLOCK_SELECTED_OUTLINE, fetchData)
  yield takeEvery(GET_PREVIOUS_SUBJECTS, fetchData)
  yield takeEvery(GET_SUBJECT_EXAMS, fetchData)
}

export default dataSaga
