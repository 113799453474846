import {
  ADD_DEVICE_TOKEN,
  SEND_FEEDBACK,
  SET_USER_SETTINGS,
  GET_USER_SETTINGS,
  GET_USER_PROFILE,
  GET_CHILD_PROFILE,
  GET_USER_MESSAGES,
  CONFIRM_PROFILE_PICTURE_UPLOAD,
  GET_PROFILE_PICTURE_UPLOAD_URL,
  CONFIRM_PROFILE_PICTURE_UPLOAD_ANY_USER,
  GET_PROFILE_PICTURE_UPLOAD_URL_ANY_USER,
  GET_ACTIVE_STAFF,
  GET_ALL_ACTIVE_EMPLOYEE_ATTENDANCE,
  GET_ALL_ACTIVE_STUDENTS_ATTENDANCE,
  GET_STUDENTS_SUBJECT_ATTENDANCE,
  GET_TEACHERS_SUBJECT_ATTENDANCE,
  ACTIVATE_USERS,
  INACTIVATE_USERS,
  MOVE_USERS_TO_JUNK,
  USER_IMPERSONATE,
} from '../../Actions/User/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../SagaHelper'

function* dataSaga() {
  yield takeEvery(ADD_DEVICE_TOKEN, fetchData)
  yield takeEvery(SEND_FEEDBACK, fetchData)
  yield takeEvery(SET_USER_SETTINGS, fetchData)
  yield takeEvery(GET_USER_SETTINGS, fetchData)
  yield takeEvery(GET_USER_PROFILE, fetchData)
  yield takeEvery(GET_CHILD_PROFILE, fetchData)
  yield takeEvery(GET_ACTIVE_STAFF, fetchData)
  yield takeEvery(GET_USER_MESSAGES, fetchData)
  yield takeEvery(GET_PROFILE_PICTURE_UPLOAD_URL, fetchData)
  yield takeEvery(GET_PROFILE_PICTURE_UPLOAD_URL_ANY_USER, fetchData)
  yield takeEvery(CONFIRM_PROFILE_PICTURE_UPLOAD, fetchData)
  yield takeEvery(GET_ALL_ACTIVE_EMPLOYEE_ATTENDANCE, fetchData)
  yield takeEvery(GET_ALL_ACTIVE_STUDENTS_ATTENDANCE, fetchData)
  yield takeEvery(GET_STUDENTS_SUBJECT_ATTENDANCE, fetchData)
  yield takeEvery(GET_TEACHERS_SUBJECT_ATTENDANCE, fetchData)
  yield takeEvery(CONFIRM_PROFILE_PICTURE_UPLOAD_ANY_USER, fetchData)
  yield takeEvery(ACTIVATE_USERS, fetchData)
  yield takeEvery(INACTIVATE_USERS, fetchData)
  yield takeEvery(MOVE_USERS_TO_JUNK, fetchData)
  yield takeEvery(USER_IMPERSONATE, fetchData)
}

export default dataSaga
