import {
  GET_STATS,
  GET_FEED,
  GET_ATTENDANCE,
  GET_NEWS,
  GET_ACCOUNT,
  GET_SCHEDULE,
  GET_TOTAL_USER,
  GET_ACTIVE_USER,
  GET_ACTIVITIES_USER,
  GET_BRANCH_LOGO,
  GET_CURRENT_SESSION,
  GET_DASHBOARD_FEE_STATS,
} from './ActionTypes/ApiActionTypes'
// import Constants from '../../../Constants';

import Constants from 'common/Constants'

export const getStats = (body, onSuccess) => {
  return {
    type: GET_STATS,
    payload: {
      onSuccess: onSuccess,
      apiUrl: Constants.api_get_stats,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}

export const getFeed = (body, onSuccess) => {
  return {
    type: GET_FEED,
    typeVariant: body.type,
    payload: {
      onSuccess: onSuccess,
      apiUrl: Constants.api_get_feed,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}

export const getTotalUser = (body, onSuccess, onFailure) => {
  return {
    type: GET_TOTAL_USER,
    payload: {
      onSuccess: onSuccess,
      onFailure,
      apiUrl: Constants.api_get_total_user,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
  }
}

export const getActiveUser = (body, onSuccess, onFailure) => {
  return {
    type: GET_ACTIVE_USER,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_active_user,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
  }
}

export const getActivitiesUser = (body, onSuccess, onFailure) => {
  return {
    type: GET_ACTIVITIES_USER,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_activities_user,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
  }
}

export const getAttendance = (body, onSuccess, onFailure) => {
  return {
    type: GET_ATTENDANCE,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_attendance,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
  }
}

export const getNews = (body, onSuccess, onFailure) => {
  return {
    type: GET_NEWS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_news,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
  }
}

export const getAccount = (body, onSuccess, onFailure) => {
  return {
    type: GET_ACCOUNT,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_account,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
  }
}

export const getDashboardFeeStats = (body, onSuccess, onFailure) => {
  return {
    type: GET_DASHBOARD_FEE_STATS,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_dashboard_fee,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
  }
}

export const getSchedule = (body, onSuccess, onFailure) => {
  return {
    type: GET_SCHEDULE,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_schedule,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body,
      reduxActionType: '',
    },
    // payload: {
    //   onSuccess,
    //   apiUrl: Constants.api_get_schedule,
    //   getString: '',
    //   useBaseURL: true,
    //   requestType: 'POST',
    //   header: 'application/json',
    //   reduxActionType: '',
    // },
  }
}

export const getBranchLogo = (onSuccess, onFailure) => {
  return {
    type: GET_BRANCH_LOGO,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_branch_logo,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}

export const getCurrentSession = (onSuccess, onFailure) => {
  return {
    type: GET_CURRENT_SESSION,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_get_current_session,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: '',
    },
  }
}
