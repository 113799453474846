import {LOGOUT_USER} from './ActionTypes/ApiActionTypes'
import {REDUX_DESTROY_SESSION} from './ActionTypes/ReduxActionTypes'
import Constants from 'common/Constants'

export const logout = (onSuccess, onFailure) => {
  return {
    type: LOGOUT_USER,
    payload: {
      apiUrl: Constants.api_logout,
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      reduxActionType: REDUX_DESTROY_SESSION,
      onSuccess,
      onFailure,
    },
  }
}
