import {takeEvery} from 'redux-saga/effects'
import {
  GET_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
} from 'setup/redux/Actions/PurchaseOrder/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_PURCHASE_ORDERS, fetchData)
  yield takeEvery(GET_PURCHASE_ORDER, fetchData)
}

export default dataSaga
