export const ATTENDANCE_UPLOAD = 'ATTENDANCE_UPLOAD'
export const GET_TODAY_ATTENDANCE = 'GET_TODAY_ATTENDANCE'
export const GET_STD_MOTNTHWISE_ATTENDANCE = 'GET_STD_MOTNTHWISE_ATTENDANCE'

export const ATTENDANCE_SETTING = 'ATTENDANCE_SETTING'
export const UPDATE_ATTENDANCE_SETTING = 'UPDATE_ATTENDANCE_SETTING'

export const GET_USER_CUSTOM_ATTENDANCE_SCHEDULE = 'GET_USER_CUSTOM_ATTENDANCE_SCHEDULE'
export const GET_ONE_USER_CUSTOM_ATTENDANCE_SCHEDULE = 'GET_ONE_USER_CUSTOM_ATTENDANCE_SCHEDULE'
export const ADD_USER_CUSTOM_ATTENDANCE_SCHEDULE = 'ADD_USER_CUSTOM_ATTENDANCE_SCHEDULE'
export const UPDATE_USER_CUSTOM_ATTENDANCE_SCHEDULE = 'UPDATE_USER_CUSTOM_ATTENDANCE_SCHEDULE'
export const DELETE_USER_CUSTOM_ATTENDANCE_SCHEDULE = 'DELETE_USER_CUSTOM_ATTENDANCE_SCHEDULE'
export const DELETE_ALL_USER_CUSTOM_ATTENDANCE_SCHEDULE =
  'DELETE_ALL_USER_CUSTOM_ATTENDANCE_SCHEDULE'
export const ATTENDANCE_ADD_ATTENDANCE_REMARKS = 'ATTENDANCE_ADD_ATTENDANCE_REMARKS'
export const MARK_USER_ATTENDANCE = 'MARK_USER_ATTENDANCE'
export const UPDATE_ATTENDANCE_TIME = 'UPDATE_ATTENDANCE_TIME'
export const UNMARK_USER_ATTENDANCE = 'UNMARK_USER_ATTENDANCE'
export const UPDATE_USER_ATTENDANCE = 'UPDATE_USER_ATTENDANCE'
export const ATTENDANCE_SETTINGS_BY_BRANCH = 'ATTENDANCE_SETTINGS_BY_BRANCH'
export const GENERATE_TEACHER_STAFF_INDIVIDUAL_ATTENDANCE_REPORT = 'GENERATE_TEACHER_STAFF_INDIVIDUAL_ATTENDANCE_REPORT'
export const GENERATE_TEACHER_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT = 'GENERATE_TEACHER_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT'
export const GENERATE_TEACHER_SUBJECT_COMBINE_ATTENDANCE_REPORT = 'GENERATE_TEACHER_SUBJECT_COMBINE_ATTENDANCE_REPORT'
export const GENERATE_TEACHER_STAFF_COMBINE_ATTENDANCE_REPORT = 'GENERATE_INDIVIDUAL_ATTENDANCE_REPORT'
export const GENERATE_STUDENT_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT = 'GENERATE_STUDENT_SUBJECT_INDIVIDUAL_ATTENDANCE_REPORT'
export const GENERATE_STUDENT_SUBJECT_COMBINED_ATTENDANCE_REPORT = 'GENERATE_STUDENT_SUBJECT_COMBINED_ATTENDANCE_REPORT'
export const GENERATE_STUDENT_SUBJECT_MONTHLY_ATTENDANCE_REPORT = 'GENERATE_STUDENT_SUBJECT_MONTHLY_ATTENDANCE_REPORT'
