import {FORGOT_PASSWORD, RESET_PASSWORD} from './ActionTypes/ApiActionTypes'
import Constants from 'common/Constants'

export const forgotPassword = (email, resend, onSuccess, onFailure) => {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
      apiUrl: Constants.api_forgot_password,
      noAccessToken: true,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: {
        email: email,
        resend,
      },
      reduxActionType: '',
    },
  }
}

export const resetPassword = (body, onSuccess, onFailure) => {
  return {
    type: RESET_PASSWORD,
    payload: {
      onSuccess,
      onFailure,
      apiUrl: Constants.api_reset_password,
      noAccessToken: true,
      getString: '',
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      body: body,
      reduxActionType: '',
    },
  }
}
