import {GET_FEE_HISTORY, DOWNLOAD_VOUCHER} from '../../Actions/Fee/ActionTypes/ApiActionTypes'
import fetchData from '../SagaHelper'
import {takeEvery} from 'redux-saga/effects'

function* dataSaga() {
  yield takeEvery(GET_FEE_HISTORY, fetchData)
  yield takeEvery(DOWNLOAD_VOUCHER, fetchData)
}

export default dataSaga
