import moment from 'moment'
import {FC, createContext, useContext} from 'react'
import {setLocale} from 'yup'

import yupEngLocale from './yupLocale/en'
import yupUrLocale from './yupLocale/ur'
import yupArLocale from './yupLocale/ar'
import yupFrLocale from './yupLocale/fr'

import 'moment/locale/ur'
// import 'moment/locale/ar'
import 'moment/locale/fr'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'ar' | 'de' | 'en' | 'es' | 'fr' | 'ja' | 'ur' | 'zh'
}
const initialState: Props = {
  selectedLang: 'en',
}

function getLang(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

export function isRtl() {
  const rtlLangs = ['ar', 'ur']
  const lang = getLang().selectedLang
  return rtlLangs.includes(lang)
}

export const getYupLocale = (lang: 'ar' | 'de' | 'en' | 'es' | 'fr' | 'ja' | 'ur' | 'zh') => {
  switch (lang) {
    case 'en':
      return yupEngLocale
    case 'ur':
      return yupUrLocale
    case 'ar':
      return yupArLocale
    case 'fr':
      return yupFrLocale

    default:
      return yupEngLocale
  }
}

const MetronicI18nProvider: FC = ({children}) => {
  const lang = getLang()
  const yupLocale = getYupLocale(lang.selectedLang)
  const momentLocale = lang.selectedLang === 'ar' ? 'en' : lang.selectedLang
  moment.locale(momentLocale)
  setLocale(yupLocale)
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang, getLang}
