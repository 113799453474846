import {takeEvery} from 'redux-saga/effects'
import {
  ADD_USER_CHILDREN,
  ADD_USER_EXPERIENCES,
  ADD_USER_QUALIFICATIONS,
  CHANGE_SCHOOL_USER_PASSWORD,
  EDIT_SCHOOL_USER_PROFILE,
  GET_ALL_USERS,
  GET_ALL_STAFF,
  GET_ONE_STAFF,
  GET_USER_BANK_INFO,
  GET_USER_CHILDREN,
  GET_USER_EXPERIENCES,
  GET_USER_QUALIFICATIONS,
  UPDATE_USER_BANK_INFO,
  GET_SMS_SERVICES,
  SEND_INACTIVE_USER_SMS,
} from 'setup/redux/Actions/School/User/ActionTypes/ApiActionTypes'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(EDIT_SCHOOL_USER_PROFILE, fetchData)
  yield takeEvery(CHANGE_SCHOOL_USER_PASSWORD, fetchData)
  yield takeEvery(GET_USER_QUALIFICATIONS, fetchData)
  yield takeEvery(ADD_USER_QUALIFICATIONS, fetchData)
  yield takeEvery(GET_USER_EXPERIENCES, fetchData)
  yield takeEvery(ADD_USER_EXPERIENCES, fetchData)
  yield takeEvery(GET_USER_CHILDREN, fetchData)
  yield takeEvery(ADD_USER_CHILDREN, fetchData)
  yield takeEvery(GET_USER_BANK_INFO, fetchData)
  yield takeEvery(UPDATE_USER_BANK_INFO, fetchData)
  yield takeEvery(GET_ALL_USERS, fetchData)
  yield takeEvery(GET_ALL_STAFF, fetchData)
  yield takeEvery(GET_ONE_STAFF, fetchData)
  yield takeEvery(GET_SMS_SERVICES, fetchData)
  yield takeEvery(SEND_INACTIVE_USER_SMS, fetchData)
}

export default dataSaga
